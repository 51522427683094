/** @format */

.button {
  cursor: pointer;
  box-shadow: unset;
}

.readonly,
.button {
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;

  &.small {
    padding: 0.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
  }

  &.medium {
    padding: 1rem;
    min-width: 3.25rem;
    height: 3.25rem;
  }

  &.large {
    padding: 1rem;
    min-width: 4rem;
    height: 4rem;
    font-weight: 600;
  }

  &.thin {
    width: 100%;
    padding: 0.5rem;
    min-width: 2rem;
    height: 3.25rem;
  }
}
