/** @format */

.container {
  display: flex;
  height: inherit;
  flex-direction: column;
}

.stats {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1rem;

  > h2 {
    margin: 0.5rem;
    flex-basis: 100%;
    text-align: center;
  }

  > div {
    display: flex;
    flex-direction: column;
    max-width: 3rem;
    align-items: center;
    gap: 0.5rem;

    h3,
    p {
      margin: 0;
      text-align: center;
    }

    p {
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.roundDist {
  flex: 1 1 auto;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;

  margin-top: 2rem;

  > h2 {
    margin: 0.5rem;
    text-align: center;
  }

  > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    > p {
      margin: 0;
      flex: 0 0 1rem;
      text-align: center;
    }

    > div {
      flex: 1 1 auto;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 1.25rem;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 0.25rem;

      &.current {
        background-color: rgb(111, 173, 255);
      }

      > p {
        margin: 0;
        font-weight: 600;
        color: white;
        font-size: small;
        padding: 0 0.25rem;
      }
    }
  }
}

.action {
  position: relative;
  text-align: center;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  > p {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  > div {
    display: flex;
    gap: 2rem;

    > button {
      flex: 1 1 auto;
      height: 3rem;
    }
  }
}
