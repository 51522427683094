/** @format */

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1rem;

  > h2 {
    margin: 0.5rem;
    flex-basis: 100%;
    text-align: center;
  }

  > div {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
