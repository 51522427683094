/** @format */

.container {
  gap: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  .round {
    gap: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .hint {
      gap: 0.5rem;
      display: flex;
      align-items: center;
    }

    .guess {
      gap: 1.5rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;

      .possibleWord,
      .word {
        gap: 0.5rem;
        display: flex;

        .hintLetter {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: -0.4rem;
            right: -0.4rem;
            bottom: -0.7rem;
            border-bottom: 2px solid var(--green);
          }
        }
      }

      .possibleWord {
        color: var(--text-color);

        > * {
          background-color: var(--amber);
        }
      }

      .word {
        color: var(--text-color);

        > * {
          background-color: var(--green);
        }

        .hintLetter {
          background-color: var(--green);
        }
      }
    }
  }
}
