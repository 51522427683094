/** @format */

.container {
  position: relative;
  height: inherit;
  text-align: center;

  > h2,
  > h5 {
    margin: 0.5rem;
  }

  > h5 {
    margin-top: 0.75rem;
  }

  > ul {
    margin: 2rem 0;
    padding: 0 2rem;
    > li {
      margin: 1rem 0;
    }
  }

  > button {
    position: absolute;
    width: 100%;
    height: 3rem;
    left: 0;
    bottom: 0.25rem;
  }
}
