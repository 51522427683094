/** @format */

.container {
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > div {
    gap: 0.25rem;
    display: flex;
    justify-content: center;
    width: 100%;

    .backspace {
      min-width: 4rem;
    }
  }
}
