/** @format */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .letters {
    gap: 0.5rem;
    display: flex;
  }
}
