/** @format */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--backdrop-color);
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: var(--modal-box-shadow);
  background-color: var(--background-color);

  padding: 2rem;
  min-width: 350px;
  width: 100%;
  height: 100%;
  max-width: 30rem;
  max-height: 36.5rem;

  .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: 0.5rem;
    z-index: 3;
    border: unset;
    background-color: transparent;
    box-sizing: content-box;
    box-shadow: unset;
    cursor: pointer;
  }

  > * {
    z-index: 2;
    color: var(--text-color);
  }

  h1 {
    margin: 0;
  }
}
