/** @format */
:root {
  --theme: "light";
  --text-color: #333;
  --background-color: #f7f9fa;
  --font-family: "Montserrat", sans-serif;
  --backdrop-color: rgba(255, 255, 255, 0.75);

  --nav-box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  --modal-box-shadow: rgba(17, 17, 26, 0.05) 0px 0px 16px,
    rgba(17, 17, 26, 0.05) 0px 0px 24px, rgba(17, 17, 26, 0.05) 0px 0px 56px;
  --button-box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 4px;

  --green: #49d249;
  --amber: blanchedalmond;
}

body.dark {
  --theme: "dark";
  --text-color: #c8c3bc;
  --background-color: #172024;
  --font-family: "Montserrat", sans-serif;
  --backdrop-color: rgba(0, 0, 0, 0.75);

  --nav-box-shadow: rgba(222, 220, 217, 0.1) 0px 10px 10px -10px;
  --modal-box-shadow: rgba(238, 238, 229, 0.05) 0px 0px 16px,
    rgba(238, 238, 229, 0.05) 0px 0px 24px,
    rgba(238, 238, 229, 0.05) 0px 0px 56px;
  --button-box-shadow: rgba(255, 255, 255, 0.16) 0px 0px 4px;

  --green: rgb(0, 102, 0);
  --amber: rgb(81, 49, 0);
}

/* Main Content */
main {
  background-color: var(--background-color);
  color: var(--text-color);
  max-width: 500px;
  margin: auto;
  height: 100%;
}

/* Buttons */
button {
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 4px;
  padding: 10px 20px;
  border: unset;
  font-weight: bold;
  box-shadow: var(--button-box-shadow);
  cursor: pointer;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.15);
  color: var(--text-color);
}

/* Headings */
h1,
h2,
h3 {
  font-weight: bold;
  font-family: var(--font-family);
  color: var(--text-color);
  // text-transform: uppercase;
  // letter-spacing: 2px;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}
