/** @format */

.container {
  padding: 0.5rem;
  position: relative;
  box-shadow: var(--nav-box-shadow);

  display: flex;
  align-items: center;
  justify-content: space-between;

  .menuLeft {
    width: 8.5rem;

    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: 500px) {
      display: none;
    }
  }

  h1 {
    font-size: 1.5rem;
  }

  .menuRight {
    width: 8.5rem;

    gap: 0.25rem;
    display: flex;
    justify-content: flex-end;
  }
}
