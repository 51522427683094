/** @format */

.container {
  width: 7rem;
  height: 7rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 5px solid green;
  border-radius: 50%;
}
