/** @format */

.toast {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.85);
  margin-top: 1rem;
}

.container {
  z-index: 5;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
}
