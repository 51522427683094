/** @format */

.container {
  gap: 3rem;
  display: flex;
  flex-direction: column;

  .guess {
    gap: 0.5rem;
    display: flex;
    min-height: 4rem;
    align-items: center;
    justify-content: center;
  }
}
