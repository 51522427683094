/** @format */

.container {
  gap: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  .hint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .letters {
      gap: 0.5rem;
      display: flex;
    }
  }

  .timer {
    display: flex;
    justify-content: center;
  }

  .guess {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
